import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { store } from '../HOC/State'
import styles from './mainAndFooter.module.css'

export default function FooterMenu({el, selected, setSelected}) {
  const {setSelectedCategory, selectedLang} = useContext(store)

  return (
    <div className={styles['footer-menu-each']}>
        { !el.underCategory ?
          (<Link to={el.category} onClick={() => setSelectedCategory(el.category)}>
            <div className={styles['category-name']}>
                <p >{el.name[selectedLang]}</p>
            </div>
          </Link>) :
          (<div className={styles['category-name']} onClick={() => {selected == el.category ? setSelected("") : setSelected(el.category)}}>
              <p style={{marginRight: "10px"}}>{el.name[selectedLang]}</p>
              {el.underCategory ? <FontAwesomeIcon icon={faCaretDown} /> : ""}
          </div>)
        }
        { selected == el.category ? 
        <div className={styles['undercategory-main']}>{el.underCategory.map(el => (
            <div key={el.category} className={styles['undercategory-div']} >
                  <Link to={el.category} onClick={() => setSelectedCategory(el.category)}>
                    <p className={styles['undercategory-name']}>{el.name[selectedLang]}</p>
                  </Link>    
            </div>
          
          ))}
          </div> : ""}
          
    </div>
  )
}
