import React, { useContext, useState } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'
import Menu from '../components/Menu/Menu'
import FooterMenu from '../components/ContentAndFooter/FooterMenu'
import { menu } from '../components/Menu/menuList'
import Search from '../components/Header/Search'
import Lang from '../components/Header/Lang/Lang'


export default function Home({children}) {
    const {selectedLang} = useContext(store)
    const [selected, setSelected] = useState("")

    let lang = translate[selectedLang]
  return (
    <div>
      <div className={styles['home-div']}>
        <div>
            <img src='/main.gif' alt='main' className={styles['animation']}/>
            <div className={styles['gradient']}></div>
            <div className={styles['on-animation']}>
              <div className={styles['menu-on-animation']}>
                 {menu.map(el => (
                    
                    <FooterMenu key={el.category} el={el} selected={selected} setSelected={setSelected}/>
                  ))}  
                <div className={styles['search-lang']}>
                  <Search header={{color: "white"}} from={true}/>
                  <Lang header={{color: "white"}}/>
                </div>
              </div>
               <div className={styles['name-h']}>
                <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                <div>
                    <h1 >{lang.tr_nameOfOrganization}</h1>
                    <h1>{lang.tr_nameOfOrganization2}</h1>
                </div>
              </div>
            </div>
          
          
        </div>
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
       <div className={styles['home-info-div-scroll']}>
         <div className={styles['home-info-div']}>
          <div className={`${styles['home-info']} ${styles['bgblue']}`}>
            <div className={styles['home-info-text']}>
              <div>
                  Since its foundation in 1956, Yerevan Computer Research Development Institute named after renowned scientist  Sergey Mergelyan,  with a rich history in designing and manufacturing a variety of computing technologies and systems, served as a catalyst for scientific and technological development within Armenia. 
              </div>
            </div>
            <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
              <img src="/SergeyMergelyan.png" alt="imageOf"/>
            </div>
          </div>
          <div className={`${styles['home-info']} ${styles['bggray']}`}>
          
            <div className={styles['home-info-text']}>
              <div>
                By being a forward-thinking company and leveraging its rich legacy,  Yerevan Computer Research and Development Institute  is embracing new technologies and driving innovations to contribute to the military and industrial sectors. Currently, it focuses on advanced projects, including cryptographic devices, shooting simulators, alongside software development like an electronic library, audio-video conference system, etc.
              </div>
            </div>  
            <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
              <img src="/building.jpg" alt="imageOf"/>
            </div>
          </div>
          <div className={`${styles['home-info']} ${styles['bgblue']}`}>
            <div className={styles['home-info-text']}>
              <div>
                  Since its foundation in 1956, Yerevan Computer Research Development Institute named after renowned scientist  Sergey Mergelyan,  with a rich history in designing and manufacturing a variety of computing technologies and systems, served as a catalyst for scientific and technological development within Armenia. 
              </div>
            </div>
            <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
              <img src="/SergeyMergelyan.png" alt="imageOf"/>
            </div>
          </div>
      </div>
      </div>     
      <ImagesSlide />
    </div>
    
  )
}
