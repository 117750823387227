import React, { useContext, useEffect, useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass} from  '@fortawesome/free-solid-svg-icons'
import styles from './search.module.css'
import { useLocation, useNavigate } from 'react-router'
import { store } from '../HOC/State'
import { translate } from '../../Pages/translate'

export default function Search({header}) {
    const [openInput, setOpenInput] = useState(false)
    const [search, setSearch] = useState("")
    const [touched, setTouched] = useState(false)

    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const navigate = useNavigate()
    const location = useLocation()

    const showOrSend = async () =>{
        if(search){
          navigate(`/search/${search}`)
        }else{
          setOpenInput(!openInput)
        }
    }

    useEffect(() => {
      if(location.pathname.includes('search')){
        if(!search && touched){
          navigate(-1)
        }
      }
    }, [search])
  return (
    <div className={!openInput ? styles['shearch-div'] : `${styles['shearch-div']} ${styles['search-div-border']}` } style={{borderColor: header.color}}>
        <input className={!openInput ? `${styles['search-input']}} ${styles['search-input-hide']}` : styles['search-input']} type='text' style={{color: header.color}} placeholder={lang.tr_search} value={search} onChange={e => {setSearch(e.target.value); setTouched(true)}}/>
        <FontAwesomeIcon className={styles['search-icon']} icon={faMagnifyingGlass} onClick={showOrSend} style={{color:header.color}}/>
        {!openInput && <div style={{height: "1px"}}></div>}
    </div>
  )
}
